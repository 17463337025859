<template>
  <span class="text-capitalize d-flex">
    {{email}} 
    <b-icon-check-circle-fill v-if="isUserEmail" class="ml-auto" variant="success" />
  </span>
</template>

<script>
import axios from 'axios'
export default {
  props: ['email'],
  data() {
    return {
      isUserEmail: null
    }
  },
  created: function(){
    axios
    .get(`/.netlify/functions/checkUser/?email=${this.email}`)
    .then(res=>{
      this.isUserEmail=res.data
    })
    .catch(err=>{
      console.log(err)
    })
  }
}
</script>

