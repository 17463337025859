<template>
    <b-container class="p-0" fluid="lg">
      <div class="d-flex">
        <b-button
          class="ml-auto"
          size="sm"
          variant="outline-secondary"
          @click="
            $router.push({
              name: 'Investment Detail',
              query: { tab: $route.query.tab },
            })
          "
        >
          <b-icon-list />
          {{ captable.memo.name }}
        </b-button>
      </div>
      <br />

      <b-row>
        <b-col cols="12" sm="6">
          <b-card class="bg-warning">
            <h2>
              <strong>{{ this.investor.ownership.amount }}</strong>
              <small class="ml-1"><small>shares</small></small>
            </h2>
            <h5>
              <span class="text-capitalize">
                {{ this.investor.ownership.securities }}
              </span>
            </h5>
            <hr />
            <p v-for="(v, k) in this.investor.ownership.memo" :key="v">
              <small class="text-muted">{{ k }}</small>
              <br />
              {{ v }}
            </p>
          </b-card>
        </b-col>
        <b-col cols="12" sm="6">
          <b-card class="bg-dark text-light">
            <h2 class="text-capitalize">
              <strong>
                {{ this.investor.firstName }} {{ this.investor.lastName }}
              </strong>
            </h2>
            <h5 v-for="e in this.investor.emails" :key="e">{{ e }}</h5>
            <hr />
            <p v-for="(v, k) in this.investor.contact" :key="v">
              <small class="text-muted">{{ k }}</small>
              <br />
              {{ v }}
            </p>
          </b-card>
        </b-col>
      </b-row>
      <br>
          <div v-if="this.securities.memo.type && this.securities.memo.type.toLowerCase() == 'equity'">
      <EquityMemo :securities="this.securities" :amount="this.investor.ownership.amount" />
    </div>
    
    <div v-if="this.securities.memo.type && this.securities.memo.type.toLowerCase() == 'debt'">
      <DebtMemo :securities="this.securities" :amount="this.investor.ownership.amount"/>
    </div>
    <br />

      <br />
      <b-card no-body>
        <b-card-header class="d-flex py-1">
          <small class="text-muted">Related Transactions</small>
          <div class="ml-auto">
            <b-icon
              v-if="isLoading"
              icon="arrow-clockwise"
              animation="spin"
            ></b-icon>
            <span class="text-danger">{{ transferError }}</span>
          </div>
        </b-card-header>
        <b-list-group flush>
          <b-list-group-item v-if="this.investor.transfers.length == 0">
            No Transaction found, it may take 2-3 business days to update
          </b-list-group-item>
          <b-list-group-item v-for="t in this.investor.transfers.slice().reverse()" :key="t">
            <b-row>
              <b-col cols="12" sm="3">
                <h2>
                  <strong>{{ formatter.format(t.amount) }}</strong>
                </h2>
              </b-col>
              <b-col cols="12" sm="3">
                <small v-if="t.date">
                  <b-icon-calendar-date />
                  {{ t.date.slice(0, 10) }}
                </small>
                <br />
                <strong class="text-uppercase">{{ t.status }}</strong>
              </b-col>

              <b-col cols="12" sm="3">
                <p>
                  <small class="text-muted">
                    transfer type
                  </small>
                  <br />
                  <strong class="text-uppercase">
                    {{ t.transferType }}
                  </strong>
                </p>
                <p>
                  <small class="text-muted">
                    initial Acc
                  </small>
                  <br />
                  <strong class="text-uppercase">
                    {{ t.fromBankAccount }}
                  </strong>
                </p>
                <p>
                  <small class="text-muted">
                    receiving Acc
                  </small>
                  <br />
                  <strong class="text-uppercase">
                    {{ t.toBankAccount }}
                  </strong>
                </p>
              </b-col>

              <b-col cols="12" sm="3">
                <p v-for="(v, k) in t.memo" :key="v">
                  <small class="text-muted">
                    {{ k }}
                  </small>
                  <br />
                  {{ v }}
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import EquityMemo from "../../components/equityMemo";
import DebtMemo from "../../components/debtMemo";
export default {
  components: {EquityMemo, DebtMemo},
  data: function () {
    return {
      error: '',
      transferError: '',
      id: {},
      captable: {},
      investor: {},
    }
  },
  computed: {
    ...mapGetters('user', {
      isLoggedIn: 'getUserStatus',
      user: 'getUser',
    }),
    formatter:function(){
      return new Intl.NumberFormat('en-US',{
        style: 'currency',
        currency: 'USD'
      })
    },
    index: function () {
      return this.captable.investors.findIndex((e) => e._id == this.investor_id)
    },
    securities: function() {
      return this.captable.securities.find(
        e => e.securities == this.investor.ownership.securities
      );
    },

  },
  methods: {},
  created: function () {
    this.id = this.$route.params._id
    this.investor_id = this.$route.params.investor_id
    this.investor = this.$route.params.investor
    this.captable = this.$route.params.captable
  },
}
</script>
