<template>
  <b-card class="card h-100 bg-white" no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" sm="6">
          <h2 v-if="this.amount && this.lastestNAV>0">
            <strong>{{ formatter.format(this.amount*this.lastestNAV)}}</strong>
          </h2>
          <hr>
          <b-table
            v-if="Array.isArray(this.memo.NAV)"
            :items="this.memo.NAV"
            small
            borderless
            hover
          >
            <template #cell(value)="data">
              <span class="text-capitalize">{{ formatter.format(data.value) }} / share</span>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12" sm="6">
          <h2 class="text-capitalize">
            <strong>{{this.securities.securities}}</strong>
          </h2>
          <hr>
          <div v-for="(v,k) in this.memo" :key="k" class="mb-3">
            <div v-if="k!=='NAV'">
            <small class="text-muted">{{k}}</small>
            <br />
            <span>{{v}}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: ["securities", "amount"],
  data() {
    return {};
  },
  computed: {
    formatter: function() {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
    },
    memo: function() {
      return this.$props.securities.memo;
    },
    lastestNAV: function() {
      if (this.memo.NAV) return this.memo.NAV[this.memo.NAV.length - 1].value;
      else return null;
    }
  },
  methods: {}
};
</script>

<style scoped>
</style>
