<template>
  <b-container class="p-0" fluid="lg">
    <div class="d-flex">
      <span class="text-danger">{{ error }}</span>

      <b-button class="ml-auto" size="sm" variant="secondary" @click="$router.go(-1)">
        <b-icon-chevron-left />Back
      </b-button>
    </div>
    <br />
    <b-card :key="this.captable" no-body>
      <b-card-header class="py-1 d-flex">
        <small class="text-muted">ID: {{ this.captable._id }}</small>
        <small class="text-muted ml-auto">
          <b-icon-tags />
          <span v-for="t in this.captable.tags" :key="t">{{ t }}</span>
        </small>
      </b-card-header>
      <b-card-body>
        <h3 v-if="this.captable.memo" class="text-uppercase">
          <strong>{{ this.captable.memo.name }}</strong>
        </h3>
        <p v-if="this.captable.memo">{{ this.captable.memo.description }}</p>

        <div v-if="isAdmin" class="d-flex">
          <b-button size="sm" variant="primary" @click="$bvModal.show('updateMemoModal')">
            <b-icon-pencil scale="0.8" />Edit Entity
          </b-button>
          <b-button
            size="sm"
            variant="outline-secondary"
            class="ml-auto"
            @click="$bvModal.show('confirmModal')"
          >
            <b-icon-exclamation-triangle-fill />Delete
          </b-button>
        </div>
      </b-card-body>
    </b-card>
    <br />
    <br />
    <b-card no-body>
      <b-table
        hover
        outlined
        head-variant="light"
        :items="this.positions"
        :fields="this.positionFields"
      >
        <template #cell(investors)="data">
          <b-link
            :to="{
              name: 'Captable Investor Detail',
              params: {
                id: id,
                investor_id: data.value._id,
                investor: data.value,
                captable: captable,
              },
            }"
            class="text-capitalize"
          >{{ data.value.firstName }} {{ data.value.lastName }}</b-link>
        </template>

        <template #cell(name)="data">
          <span class="text-capitalize">{{ data.value }}</span>
        </template>

        <template #cell(email)="data">
          <CheckUserEmail :email="data.value" :key="data.value" />
        </template>
      </b-table>
      <b-card-body>
        <b-button
          size="sm"
          variant="primary"
          class="ml-auto"
          @click="$bvModal.show('newInvestorModal')"
        >
          <b-icon-plus />New Ownership
        </b-button>
      </b-card-body>
    </b-card>
    <br />
    <br />
    <b-card v-for="(s,index) in captable.securities" :key="s" class="h-100">
      <h4>{{s.securities}}</h4>
      <JsonEditor
        :show-btns="true"
        :mode="'code'"
        v-model="captable.securities[index].memo"
        :expandedOnStart="true"
        @json-save="updateSecuritesMemo"
        
      ></JsonEditor>
    </b-card>
    <br />
    <b-card no-body>
      <b-list-group flush>
        <b-list-group-item v-for="u in updates" :key="u">
          <div class="d-flex">
            <h4>
              <strong>{{ u.title }}</strong>
            </h4>
            <div class="ml-auto">
              <b-badge variant="warning" class="mr-1" v-for="t in u.tags" :key="t">{{ t }}</b-badge>
            </div>
          </div>
          <small class="text-lowercase text-muted">
            <b-icon-calendar-date />
            {{ u.updatedAt.slice(0, 10) }}
            <b-icon-envelope class="ml-4" />
            {{ u.author.owner.email }}
          </small>
          <p class="w-75">{{ u.body }}</p>
          <img
            v-if="u.imgURL"
            :src="u.imgURL"
            class="w-25"
            @click="imgURL = u.imgURL"
            v-b-modal="'img-modal'"
          />
          <div class="d-flex">
            <b-button
              @click="deleteUpdate(u._id)"
              class="ml-auto"
              size="sm"
              variant="outline-secondary"
            >
              <b-icon-exclamation-triangle-fill />Delete
            </b-button>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <b-button size="sm" variant="primary" @click="$bvModal.show('newUpdateModal')">
            <b-icon-plus />New Update
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <!-- image modal -->
    <b-modal id="img-modal" size="lg" hide-footer centered>
      <img :src="this.imgURL" class="w-100" />
    </b-modal>

    <!---new update modals --->
    <b-modal
      id="newUpdateModal"
      centered
      button-size="sm"
      @ok="newUpdate"
      @cancel="resetUpdateForm"
    >
      <template #modal-title>New Updates</template>
      <b-form>
        <b-form-group label="Title">
          <b-input v-model="updateFormData.title"></b-input>
        </b-form-group>
        <b-form-group label="Text">
          <b-textarea v-model="updateFormData.body"></b-textarea>
        </b-form-group>
        <b-form-group label="tags">
          <b-form-tags v-model="updateFormData.tags"></b-form-tags>
        </b-form-group>
        <b-form-group label="image URL">
          <b-input type="url" v-model="updateFormData.imgURL"></b-input>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- confirm delete -->
    <b-modal id="confirmModal" centered button-size="sm" @ok="deleteCaptable">
      <template #modal-title>
        <span class="text-danger">
          <b-icon-exclamation-triangle-fill />Delete
        </span>
      </template>
      <h4 class="my-4" v-if="this.captable.memo">Delete {{ captable.memo.name }} ?</h4>
    </b-modal>

    <!-- update entity -->
    <b-modal
      id="updateMemoModal"
      centered
      button-size="sm"
      @ok="updateMemo"
      @cancel="resetMemoForm"
      @close="resetMemoForm"
    >
      <template #modal-title>Update Entity</template>
      <b-form>
        <b-form-group label="Entity Name">
          <b-input v-model="captableFormData.memo.name" required></b-input>
        </b-form-group>
        <b-form-group label="description">
          <b-textarea v-model="captableFormData.memo.description" required></b-textarea>
        </b-form-group>
        <b-form-group label="tags">
          <b-form-tags v-model="captableFormData.tags"></b-form-tags>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- ownership -->

    <b-modal
      id="newInvestorModal"
      centered
      button-size="sm"
      @ok="$bvModal.show('newOwnershipModal')"
      @cancel="resetInvestorForm"
      @close="resetInvestorForm"
    >
      <template #modal-title>New Owner Information</template>
      <b-form>
        <b-form-group label="First Name">
          <b-input v-model="investorFormData.firstName" required></b-input>
        </b-form-group>
        <b-form-group label="Last Name">
          <b-input v-model="investorFormData.lastName" required></b-input>
        </b-form-group>
        <b-form-group label="Email">
          <b-input type="email" v-model="investorFormData.emails[0]" required></b-input>
        </b-form-group>
        <b-form-group label="Phone">
          <b-input type="tel" v-model="investorFormData.contact.phone"></b-input>
        </b-form-group>
        <b-form-group label="Address">
          <b-input v-model="investorFormData.contact.address"></b-input>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="newOwnershipModal"
      centered
      button-size="sm"
      @ok="addNewInvestor()"
      @cancel="resetInvestorForm()"
      @close="resetInvestorForm()"
    >
      <template #modal-title>Owner's Securities</template>
      <b-form>
        <b-form-group label="Securities Name">
          <b-input v-model="investorFormData.ownership.securities" required></b-input>
        </b-form-group>
        <b-form-group label="Amount">
          <b-input type="number" v-model="investorFormData.ownership.amount"></b-input>
        </b-form-group>
        <b-form-group label="Description">
          <b-input v-model="investorFormData.ownership.memo.description"></b-input>
        </b-form-group>
        <b-form-group label="Order ID if Any">
          <b-input v-model="investorFormData.ownership.memo.order"></b-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
const axios = require("axios");

import { mapGetters } from "vuex";
import JsonEditor from "vue-json-editor";
import CheckUserEmail from "../../components/checkUserEmail";
export default {
  components: { CheckUserEmail, JsonEditor },
  data: function() {
    return {
      error: "",
      captable: {},
      updates: [],
      captableFormData: {
        memo: {}
      },
      investorFormData: {},
      updateFormData: {},
      imgURL: null
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser"
    }),
    id: function() {
      return this.$route.params.id;
    },
    isLoading: function() {
      return this.$store.isLoading;
    },
    positionFields: function() {
      return [
        { key: "investors", sortable: true, sortKey: "firstName" },
        { key: "securitiesName", label: "securities", sortable: true },
        { key: "amount", sortable: true },
        { key: "email", sortable: true },
        { key: "transfers" }
      ];
    },
    positions: function() {
      let items = [];
      for (var i = 0; i < this.captable.investors.length; i++) {
        items.push({
          investors: this.captable.investors[i],
          securitiesName: this.captable.investors[i].ownership.securities,
          amount: this.captable.investors[i].ownership.amount
            ? this.captable.investors[i].ownership.amount
            : "n/a",
          name: `${this.captable.investors[i].firstName} ${this.captable.investors[i].lastName}`,
          email: this.captable.investors[i].emails[0],
          transfers: this.captable.investors[i].transfers.length
        });
      }
      return items;
    }
  },
  methods: {
    isAdmin: function() {
      console.log(this.captable.admin);
      return this.captable.admin.indexOf(this.user.db_token) != -1;
    },
    updateSecuritesMemo: function() {
      this.$store.isLoading = true;
      axios
        .put("/.netlify/functions/captable", this.captable)
        .then(res => {
          this.error = "";
          this.captable = res.data;
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.$store.isLoading = false;
        });
    },

    resetInvestorForm: function() {
      this.investorFormData = {
        firstName: "",
        lastName: "",
        emails: [""],
        contact: {},
        transfers: [],
        ownership: {
          securities: "",
          amount: "",
          memo: {}
        }
      };
    },

    resetUpdateForm: function() {
      this.updateFormData = {};
    },
    async newUpdate() {
      this.$store.isLoading = true;
      axios
        .post("/.netlify/functions/update/", {
          ...this.updateFormData,
          captable: this.captable._id
        })
        .then(res => {
          this.updates.push(res.data);
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.$store.isLoading = false;
        });
    },
    async deleteUpdate(id) {
      this.$store.isLoading = true;
      let index = this.updates.findIndex(e => e._id === id);
      axios
        .delete("/.netlify/functions/update/", {
          params: {
            _id: id
          }
        })
        .then(() => {
          this.updates.splice(index, 1);
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.$store.isLoading = false;
        });
    },
    async addNewInvestor() {
      this.$store.isLoading = true;
      this.captable.investors.push({ ...this.investorFormData });
      axios
        .put("/.netlify/functions/captable", this.captable)
        .then(res => {
          this.captable = res.data;
          this.error = "";
          this.resetInvestorForm();
        })
        .catch(err => {
          this.captable.investors.pop();
          this.error = err;
        })
        .finally(() => {
          this.$store.isLoading = false;
        });
    },
    resetMemoForm: function() {
      this.captableFormData.tags = this.captable.tags;
      Object.assign(this.captableFormData.memo, this.captable.memo);
    },
    async updateMemo() {
      this.$store.isLoading = true;
      this.captable.tags = this.captableFormData.tags;
      let originData = {};
      Object.assign(originData, this.captable.memo); //save original data

      Object.assign(this.captable.memo, this.captableFormData.memo);
      axios
        .put("/.netlify/functions/captable", this.captable)
        .then(res => {
          this.error = "";
          this.captable = res.data;
        })
        .catch(err => {
          Object.assign(this.captable.memo, originData);
          this.error = err;
        })
        .finally(() => {
          this.$store.isLoading = false;
        });
    },
    async deleteCaptable() {
      console.log("delete captable");
      this.$store.isLoading = true;
      await axios.delete("/.netlify/functions/captable/", {
        params: {
          _id: this.captable._id
        }
      });
      this.$store.isLoading = false;
      this.$router.go(-1);
    }
  },
  created: async function() {
    this.$store.isLoading = true;
    axios
      .get(`/.netlify/functions/captable/?_id=${this.id}`)
      .then(res => {
        this.captable = res.data[0];
        this.captableFormData.tags = this.captable.tags;
        Object.assign(this.captableFormData.memo, this.captable.memo);
        this.resetInvestorForm();
      })
      .then(() => {
        return axios.get(`/.netlify/functions/update/?captable=${this.id}`);
      })

      .then(res => {
        this.updates = res.data;
      })
      .catch(err => {
        this.error = err;
      })
      .finally(() => {
        this.$store.isLoading = false;
      });
  }
};
</script>
