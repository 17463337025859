<template>
  <b-container class="p-0" fluid="lg">
    <div class="d-flex">
      <span class="text-danger">{{ error }}</span>

      <b-button
        class="ml-auto"
        size="sm"
        variant="outline-secondary"
        @click="$router.push({ name: 'Investment List' })"
      >
        <b-icon-list />Captables
      </b-button>
    </div>
    <br />
    <b-card :key="this.captable" no-body border-variant="light">
      <div class="d-flex">
        <small class="text-muted">
          <b-icon-tags />
          <span v-for="t in this.captable.tags" :key="t">{{ t }}</span>
        </small>
        <small class="text-muted ml-auto">Captable ID: {{ this.captable._id }}</small>
      </div>
      <h3 v-if="this.captable.memo" class="text-uppercase">
        <strong>{{ this.captable.memo.name }}</strong>
      </h3>
      <p v-if="this.captable.memo">{{ this.captable.memo.description }}</p>
      <br />
    </b-card>
    <b-row>
      <b-col cols="12" sm="4" v-for="s in this.captable.investors" :key="s" class="my-2">
        <b-card bg-variant="warning" border-variant="warning">
          <h2>
            <strong>{{ s.ownership.amount }}</strong>
            <small class="ml-1">
              <small>shares</small>
            </small>
          </h2>
          <h5>
            <span class="text-capitalize">{{ s.ownership.securities }}</span>
          </h5>
          <div class="d-flex">
            <b-button
              :to="{
                  name: 'Investment Investor Detail',
                  params: {
                    id: id,
                    investor_id: s._id,
                    investor: s,
                    captable: captable,
                  },
                  query: {
                    tab: 'News',
                  },
                }"
              size="sm"
              variant="dark"
              class="ml-auto"
            >
              Detail and Transactions
              <b-badge variant="warning">{{ s.transfers.length }}</b-badge>
            </b-button>
          </div>
        </b-card>
        <div v-if="s.transfers.length > 0">
          <b-card
            class="text-light my-2"
            bg-variant="dark"
            border-variant="dark"
            v-for="t in s.transfers.slice(-1)"
            :key="t"
          > 
            <small class="text-muted"> Latest Transaction</small>
            <h2>
              {{ formatter.format(t.amount) }}
            </h2>
            <div class="d-flex">
                <small class="mr-auto text-muted">{{ t.date.slice(0, 10) }}</small>

            </div>
            <div class="d-flex">
              <span> {{ t.memo.usage }}</span>
              <span class="ml-auto">{{ t.status }}</span>
            </div>

          </b-card>
        </div>
        <div v-else>
          <b-card class="text-light my-2" bg-variant="dark " border-variant="dark">
            <strong>No Transaction Recorded</strong>
            <br />
            <span class="text-muted">it may take 2-3 business days for your captable manager to update</span>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <br>
    <br>
    <b-tabs pill>
      <b-tab :active="this.$route.query.tab == 'News'">
        <template #title>
          <strong>News</strong>
          <b-badge class="ml-1" variant="dark">{{ updates.length }}</b-badge>
        </template>
        <br />

        <b-row>
          <b-col cols="12" v-if="updates.length == 0">Admin has not created any updates</b-col>
          <b-col cols="12" v-for="u in updates.slice().reverse()" :key="u" class="mb-4">
            <b-card class="h-100" no-body border-variant="gray">
              <b-row class="justify-content-between">
                <b-col cols="12" sm="8">
                  <b-card-body>
                    <h4>
                      <strong>{{ u.title }}</strong>
                    </h4>
                    <small class="text-lowercase text-muted">
                      <b-icon-calendar-date />
                      {{ u.updatedAt.slice(0, 10) }}
                      <b-icon-envelope class="ml-2" />
                      {{ u.author.owner.email }}
                    </small>
                    <small v-for="t in u.tags" :key="t" class="ml-2 text-muted">
                      <b-icon-tag />
                      {{ t }}
                    </small>
                    <br />
                    <p>{{ u.body }}</p>
                  </b-card-body>
                </b-col>
                <b-col cols="12" sm="4">
                  <img
                    v-if="u.imgURL"
                    :src="u.imgURL"
                    style="object-fit: cover; max-height: 230px; width: 100%"
                    v-b-modal="'img-modal'"
                    @click="imgURL = u.imgURL"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-modal id="img-modal" size="lg" centered hide-footer>
          <img :src="this.imgURL" class="w-100" />
        </b-modal>
      </b-tab>
      <b-tab :active="this.$route.query.tab == 'Investments'">
        <template #title>
          <div class="text-center">
            <strong>Investments</strong>
            <b-badge class="ml-1" variant="dark">{{ positions.length }}</b-badge>
          </div>
        </template>
        <br />
        <b-card no-body class="bg-white border-gray">
          <b-table hover :items="this.positions" :fields="this.positionFields" borderless>
            <template #cell(securities)="data">
              <b-link
                :to="{
                    name: 'Investment Investor Detail',
                    params: {
                      id: id,
                      investor_id: data.value._id,
                      investor: data.value,
                      captable: captable,
                    },
                    query: {
                      tab: 'Investments',
                    },
                  }"
                class="text-capitalize"
              >{{ data.value.ownership.securities }}</b-link>
            </template>

            <template #cell(name)="data">
              <span class="text-capitalize">{{ data.value }}</span>
            </template>
          </b-table>
        </b-card>
      </b-tab>

      <b-tab :active="this.$route.query.tab == 'Transactions'">
        <template #title>
          <div class="text-center">
            <strong>Transactions</strong>
            <b-badge class="ml-1" variant="dark">{{ transfers.length }}</b-badge>
          </div>
        </template>

        <br />
        <b-card no-body class="bg-white border-gray">
          <b-table hover :items="this.transfers" :fields="this.transferFields" borderless>
            <template #cell(date)="data">{{ data.value.slice(0, 10) }}</template>
            <template #cell(amount)="data">${{ data.value }}</template>

            <template #cell(memo)="data">
              <div v-if="data.value.usage">{{ data.value.usage }}</div>
              <div v-if="data.value.info">{{ data.value.info }}</div>
              <div v-if="data.value.order">order-{{ data.value.order }}</div>
            </template>
          </b-table>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
const axios = require("axios");

import { mapGetters } from "vuex";
//import Updates from '../../components/Updates'

export default {
  components: {},
  data: function() {
    return {
      error: "",
      captable: {},
      updates: [],
      imgURL: null
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser"
    }),
        formatter:function(){
      return new Intl.NumberFormat('en-US',{
        style: 'currency',
        currency: 'USD'
      })
    },
    id: function() {
      return this.$route.params.id;
    },
    positionFields: function() {
      return [
        { key: "securities", sortable: true },
        { key: "amount", sortable: true, label: "units" },
        { key: "transfers", label: "transcations" }
      ];
    },
    positions: function() {
      let items = [];
      for (var i = 0; i < this.captable.investors.length; i++) {
        items.push({
          securities: this.captable.investors[i],
          amount: this.captable.investors[i].ownership.amount
            ? this.captable.investors[i].ownership.amount
            : "n/a",
          name: `${this.captable.investors[i].firstName} ${this.captable.investors[i].lastName}`,
          email: this.captable.investors[i].emails[0],
          transfers: this.captable.investors[i].transfers.length
        });
      }
      return items;
    },
    transferFields: function() {
      return [
        { key: "date", sortable: true },
        { key: "amount", sortable: true },
        { key: "status", sortable: true },
        { key: "fromBankAccount", sortable: true, label: "From" },
        { key: "toBankAccount", sortable: true, label: "To" },
        { key: "memo", sortable: true, label: "Memo" }
      ];
    },
    transfers: function() {
      let t = [];
      this.captable.investors.forEach(e => {
        t = t.concat(e.transfers);
      });
      return t;
    }
  },
  methods: {},
  created: async function() {
    this.$store.isLoading = true;
    axios
      .get(`/.netlify/functions/investment/?_id=${this.id}`)
      .then(res => {
        this.captable = res.data[0];
        return axios.get(`/.netlify/functions/update/?captable=${this.id}`);
      })
      .then(res => {
        this.updates = res.data;
      })
      .catch(err => {
        this.error = err;
      })
      .finally(() => {
        this.$store.isLoading = false;
      });
  }
};
</script>
