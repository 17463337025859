<template>
  <b-card class="card h-100 bg-white mb-5" no-body>
    <b-card-header class="d-flex py-1">
      <small class="text-muted">Principle value</small>
    </b-card-header>
    <b-card-body>
      <h2 v-if="this.amount && this.memo.principle">
        <strong>{{ formatter.format(this.amount*this.memo.principle)}}</strong>
      </h2>
      <hr />
      <b-row>
        <b-col cols="12" sm="6">
          <h5 class="text-capitalize">{{this.securities.securities}}</h5>
        </b-col>
        <b-col cols="12" sm="6"> 
          <div v-for="(v,k) in this.memo" :key="k" class="mb-3">
          <small class="text-muted">{{k}}</small>
          <br />
          {{v}}
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: ["securities", "amount"],
  data() {
    return {};
  },
  computed: {
    formatter: function() {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
    },
    memo: function() {
      return this.$props.securities.memo;
    }
  },
  methods: {}
};
</script>

<style scoped>
</style>
